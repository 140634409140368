import { action, revalidate, reload, json } from "@solidjs/router";
import { _sendItemToFavourites } from "../roma-api/favourites";
import { FavouriteTypeOption } from "../roma-api/favourites/types";
import { getFavourites } from "./useFavourites";

export const addFavouriteAction = action(
  async (sku: string, type: FavouriteTypeOption) => {
    const response = await _sendItemToFavourites(sku, type);

    return json(response, { revalidate: getFavourites.key });
    // return reload({revalidate: getFavourites.key })
  }
);
