import { getSession } from "~/services/session";
import { FavouritesResponse } from "./types";

export const _removeItemFromFavourites = async (
  id: string
): Promise<FavouritesResponse> => {
  const session = await getSession();

  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/favourite/${id}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${session?.token}`,
      },
    }
  );

  if (!response.ok) {
    // TODO Error handling
    const data = await response.json();
    throw new Error(`Error deleting ID '${id}' from favourites: ${data}`);
  }

  const data: FavouritesResponse = await response.json();

  return data;
};
