import { action, cache, reload } from "@solidjs/router";

import {
  type RomaSessionData,
  getSessionOnServer,
  setSessionOnServer,
} from "./session";

import { listCarts } from "../cart/useListCarts";

import { getFavourites } from "../favourites";

/**
 * [SERVER]
 * @returns session data or null
 */
export const _getSession = async () => {
  "use server";
  try {
    const session = await getSessionOnServer();
    if (!session.data.token) return null;
    return session.data;
  } catch {
    return null;
  }
};

/**
 * Returns cached session data/null, to be used with createAsync(), or called within route preload fn.
 */
export const getSession = cache(() => _getSession(), "session");

/**
 * [SERVER] fn to set values on session
 * @param data Object structured as RomaSessionData
 */
export const setSession = async (data: Partial<RomaSessionData>) => {
  "use server";
  await setSessionOnServer(data);
};

/**
 * [SERVER] setSession fn wrapped in action(), revalidates getSession() cache
 */
export const setSessionAction = action(
  async (data: Partial<RomaSessionData>) => {
    "use server";
    await setSession(data);
    // return reload({revalidate: getSession.key})
    return reload({ revalidate: revalidateOnSession });
  }
);

/**
 * [SERVER] function that clears token,name,last_login values on session data.
 */
export const logout = async () => {
  "use server";
  await setSession({
    token: undefined,
    name: undefined,
    last_login: undefined,
    permissions: undefined,
  });
};

/**
 * [SERVER] logout fn wrapped in action(), revalidates getSession() cache.
 */
export const logoutAction = action(async () => {
  "use server";
  await logout();
  return reload({ revalidate: revalidateOnSession });
  // return reload({ revalidate: getSession.key });
});

// TODO - maybe shouldnt be defined here, but alongside session context using getSession()?
export const getIsPartner = cache(async () => {
  "use server";
  const session = await getSessionOnServer();
  return session.data.token !== undefined && session.data.token !== "";
}, "is-partner");

export const revalidateOnSession = [
  getSession.key,
  getIsPartner.key,
  listCarts.key,
  getFavourites.key
];
