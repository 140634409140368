export type ProductType = string;

import { ProductPriceResponse } from "~/services/roma-api/products/types";

// TODO
export enum PT {
  MOULDING = "moulding",
  LENGTH = "length",
  JOIN = "join",
  CHOP = "chop",
  RAIL = "rail",
  PHOTOFRAME = "photoFrame",
  GALLERYFRAME = "galleryFrame",
  MIRROR = "mirror",
  BOX = "box",
  CONTRACT = "contract",
  STACK = "stack",
  CORNERSAMPLE = "cornerSample",
}

export const PTLabels: Record<`${PT}`, string> = {
  [PT.MOULDING]: "Moulding",
  [PT.LENGTH]: "Length",
  [PT.JOIN]: "Join",
  [PT.CHOP]: "Chop",
  [PT.RAIL]: "Rail",
  [PT.PHOTOFRAME]: "Photo Frame",
  [PT.GALLERYFRAME]: "Gallery Frame",
  [PT.MIRROR]: "Mirror",
  [PT.BOX]: "Box",
  [PT.CONTRACT]: "Contract",
  [PT.STACK]: "Stack",
  [PT.CORNERSAMPLE]: "Corner Sample",
};



// ! PREVIOUS
export const PHOTOFRAME = "photoFrame" as const;
export const LENGTH = "length" as const;
export const CONTRACT = "contract" as const;
export const RAIL = "rail" as const;
export const BOX = "box" as const;
export const JOIN = "join" as const;
export const CHOP = "chop" as const;
export const CORNERSAMPLE = "cornerSample" as const;
export const MOULDING = "moulding" as const;
export const GALLERYFRAME = "galleryFrame" as const;
export const MIRROR = "mirror" as const;
export const STACK = "stack" as const;


//! testing..redefine the existing product constants to avoid replacing every instance?
// export const PHOTOFRAME = PT.PHOTOFRAME;
// export const LENGTH = PT.LENGTH;
// export const CONTRACT = PT.CONTRACT;
// export const RAIL = PT.RAIL;
// export const BOX = PT.BOX;
// export const JOIN = PT.JOIN;
// export const CHOP = PT.CHOP;
// export const CORNERSAMPLE = PT.CORNERSAMPLE;
// export const MOULDING = PT.MOULDING;
// export const GALLERYFRAME = PT.GALLERYFRAME;
// export const MIRROR = PT.MIRROR;
// export const STACK = PT.STACK;

export const pathMapping: Record<string, string> = {
  [PHOTOFRAME]: "/photo-frame",
  [GALLERYFRAME]: "/gallery-frame",
  [MIRROR]: "/mirror",
  [MOULDING]: "",
};

export const productLabels = {
  [PHOTOFRAME]: "Photo Frame",
  [MOULDING]: "Moulding",
  [GALLERYFRAME]: "Gallery Frame",
  [MIRROR]: "Mirror",
  [LENGTH]: "Length",
  [JOIN]: "Join",
  [CHOP]: "Chop",
  [RAIL]: "Rail",
  [BOX]: "Box",
  [CORNERSAMPLE]: "Corner Sample",
  [CONTRACT]: "Contract",
};

export const productTypes = {
  [PHOTOFRAME]: 0,
  [MOULDING]: 1,
  [GALLERYFRAME]: 2,
  [MIRROR]: 3,
};

export const productTypeNumbers = [PHOTOFRAME, MOULDING, GALLERYFRAME, MIRROR];

// TODO ? deprecated from previous codebase - remove?
// export const hasProduct = (arr: string, sku: string, type = "moulding") => {
//   if (!arr) return false;
//   const key = JSON.stringify([sku, productTypes[type]]);
//   return arr.includes(key);
// };

// TODO ? deprecated from previous codebase - remove?
// export const serializeProducts = (
//   input: string,
//   sku: string,
//   type: ProductType,
//   action: "append" | "toggle",
//   maxSize = 99
// ) => {
//   const key = [sku, productTypes[type]];
//   const arr = JSON.parse(input) || [];
//   let location = -1;
//   // Find the position in the array
//   for (const i in arr) {
//     if (arr[i][0] == key[0] && arr[i][1] == key[1]) {
//       location = parseInt(i);
//       break;
//     }
//   }
//   // Add it to the beginning
//   if (location === -1) {
//     arr.unshift(key);
//     // Remove or toggle
//   } else {
//     arr.splice(location, 1);
//     if (action == "append") arr.unshift(key);
//   }
//   arr.slice(0, maxSize);
//   return JSON.stringify(arr);
// };

export const imageUrl = (
  sku: string,
  type = "moulding",
  num: number
): string => {
  let moulding = "";
  if (sku !== undefined) {
    moulding = sku;
  }
  let typePath = "mouldings";
  if (type === PHOTOFRAME) {
    typePath = "photo-frames";
  } else if (type === GALLERYFRAME) {
    typePath = "gallery-frames";
  }
  return `${import.meta.env.VITE_ROMA_CDN}/${typePath}/${moulding}-${num}.png`;
};

export const skuUrl = (sku: string, type = "moulding"): string => {
  let typePath = "";
  if (type === PHOTOFRAME) {
    typePath = "/photo-frame";
  } else if (type === GALLERYFRAME) {
    typePath = "/gallery-frame";
  } else if (type === MIRROR) {
    typePath = "/mirror";
  }
  return `/product/${sku}${typePath}`;
};
