import { getSession } from "~/services/session";
import { FavouritesResponse } from "./types";

export const _retrieveFavourites = async (): Promise<FavouritesResponse> => {
  const session = await getSession();

  if (!session) return { Total: 0, Results: [] };

  // TODO - redirect if no session / session is close to expiring?

  // const response = await apiFetch(......., no internet, )

  // AppError -> everything befre you touch the API -> should be handled/thrown from apiFetch fn.

  // APIError -> conncted to API, bad response/bad data -> handled directly inside action/async/ (?) cache fn

  // try {
  //  const response = await apiFetch(.......)
  // } catch (error) {
  // if (err instanceof SOMEERROR) {}
  // }

  const response = await fetch(`${import.meta.env.VITE_ROMA_API}/favourite`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${session?.token}`,
    },
  });

  // TODO Error handling
  if (!response.ok) {
    // const data = await response.json();
    const errorText = await response.text();
    // console.log("ERROR FROM _RETRIEVE FAVS....", errorText);
    throw new Error(`Error retrieving account favourites: ${errorText}`);
  }

  const data: FavouritesResponse = await response.json();

  return data;
};
