import { cache, createAsync } from "@solidjs/router";
import { _retrieveFavourites } from "../roma-api/favourites";
import { type FavouritesResponse } from "../roma-api/favourites/types";

const turnFavsIntoMap = (favourites: FavouritesResponse["Results"]) => {
  const map = new Map();

  favourites.forEach((item) => {
    map.set(`${item.SKU}-${item.Type}`, { ID: item.ID });
  });

  return map;
};

export const getFavourites = cache(async () => {
  const favourites = await _retrieveFavourites();

  const map = turnFavsIntoMap(favourites.Results);

  return map;

  // ? As try/catch
  // try {
  //   const favourites = await _retrieveFavourites();

  //   const map = turnFavsIntoMap(favourites.Results);

  //   return map;
  // } catch (error) {

  //   console.log("error in catch-block of getFavourites cache >>> ", error);
  //   return new Map();
  // }
}, "favourites");

// TODO - create the hasFavourite/isFavourite fn to check the cache and return the id (needed to delete)
// ?to be used/passed in the Session context?
export const useFavourites = () => createAsync(() => getFavourites());
