import { action, revalidate, reload, json } from "@solidjs/router";
import { _removeItemFromFavourites } from "../roma-api/favourites";
import { getFavourites } from "./useFavourites";

export const deleteFavouriteAction = action(async (id: string) => {


  const response = await _removeItemFromFavourites(id);

  return json(response, { revalidate: getFavourites.key });
  // return reload({revalidate: getFavourites.key})
});
