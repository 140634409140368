import { getSession } from "~/services/session";
import type { CartSummary } from "./types";
import type { ListResponse } from "../products/types";

export type ListCartResponse = Pick<ListResponse<CartSummary>, "Results" | "Total">;

export const _listCarts = async (): Promise<ListCartResponse> => {
  const session = await getSession();

  // TODO.. stop this from firing elsewhere instead of returning early?
  if (!session?.token) return;

  const response = await fetch(`${import.meta.env.VITE_ROMA_API}/cart/`, {
    headers: {
      Authorization: `Bearer ${session?.token}`,
    },
  });

  if (!response.ok) {
    // TODO
    const data = await response.json();
    throw new Error(`Error retrieving carts: ${JSON.stringify(data)}`);
  }

  const data: ListCartResponse = await response.json();
  return data;
};
