import { getSession } from "~/services/session";
import type { CartDetail } from "./types";

export const _retrieveCart = async (
  id: string = "current"
): Promise<CartDetail> => {
  "use server";
  // console.log("_retrieveCart firing, id: ", id);

  const session = await getSession();

  const response = await fetch(`${import.meta.env.VITE_ROMA_API}/cart/${id}`, {
    headers: {
      Authorization: `Bearer ${session?.token}`,
    },
  });

  if (!response.ok) {
    // TODO
    const errorText = await response.text();
    throw new Error(`Error retrieving cart: ${errorText}`);
  }

  const data: CartDetail = await response.json();
  return data;
};
