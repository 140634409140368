import { getSession } from "~/services/session";
import type { CreateCartResponse } from "./types";

export const _createNewCart = async (
  name?: string
): Promise<CreateCartResponse> => {
  "use server";
  console.log("_createCart firing, name: ", name);

  const session = await getSession();

  const response = await fetch(`${import.meta.env.VITE_ROMA_API}/cart`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${session?.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Name: name ?? null }),
  });

  if (!response.ok) {
    // TODO
    const data = await response.json();
    throw new Error(`Error creating cart: ${data}`);
  }

  const data: CreateCartResponse = await response.json();
  return data;
};
