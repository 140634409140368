import { getSession } from "~/services/session";
import { FavouriteTypeOption, FavouritesResponse } from "./types";
import { PT } from "~/utils/products";

export const _sendItemToFavourites = async (
  sku: string,
  type: FavouriteTypeOption = PT.MOULDING
): Promise<FavouritesResponse> => {
  const session = await getSession();

  const response = await fetch(`${import.meta.env.VITE_ROMA_API}/favourite`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${session?.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ SKU: sku, Type: type }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(`Error sending item to favourites: ${data}`);
  }

  const data: FavouritesResponse = await response.json();
  return data;
};
